import "./src/css/style.css";
import React from "react"
import 'focus-visible/dist/focus-visible'
import { Global, css } from '@emotion/react'
import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react"

// Extend the base theme with "src/@chakra-ui/gatsby-plugin/theme.js"
const customTheme = extendTheme({});

// Remove unvanted "focus-visible" and "text selection" outline ring and higlight on mouse click and mobile touch but keep it when navigating with keyboard.
const GlobalStyles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }

   button, a, div, span, svg {
    -webkit-tap-highlight-color: transparent;
     -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
   }
`;

// Global wrapper component to eneable Chakra UI styling in the app
export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider theme={customTheme}>
      <Global styles={GlobalStyles}/>
      <CSSReset />
      {element}
    </ChakraProvider>
  )
}