// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arak-old-js": () => import("./../../../src/pages/arak-old.js" /* webpackChunkName: "component---src-pages-arak-old-js" */),
  "component---src-pages-enteriorok-js": () => import("./../../../src/pages/enteriorok.js" /* webpackChunkName: "component---src-pages-enteriorok-js" */),
  "component---src-pages-epuletek-js": () => import("./../../../src/pages/epuletek.js" /* webpackChunkName: "component---src-pages-epuletek-js" */),
  "component---src-pages-index-embla-js": () => import("./../../../src/pages/index-embla.js" /* webpackChunkName: "component---src-pages-index-embla-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-ingatlanok-js": () => import("./../../../src/pages/ingatlanok.js" /* webpackChunkName: "component---src-pages-ingatlanok-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-magamrol-js": () => import("./../../../src/pages/magamrol.js" /* webpackChunkName: "component---src-pages-magamrol-js" */),
  "component---src-pages-uzenet-visszajelzes-js": () => import("./../../../src/pages/uzenet-visszajelzes.js" /* webpackChunkName: "component---src-pages-uzenet-visszajelzes-js" */),
  "component---src-pages-varoskep-js": () => import("./../../../src/pages/varoskep.js" /* webpackChunkName: "component---src-pages-varoskep-js" */)
}

